// autocomplete field
(function($) {

    $.autocompleteField = function(element, userSettings) {

        // check required properties
        var requiredProps = ['getOptionsFromResponse'];
        for (var i = 0, len = requiredProps.length; i < len; i++) {
            var prop = requiredProps[i];
            if (!(prop in userSettings)) {
                console.log('"' + prop + '" property is required');
                return;
            }
        }

        // check paired properties
        if (!('url' in userSettings) && !('load' in userSettings)) {
            console.log('"url" or "load" property must be specified');
            return;
        }
        if (('url' in userSettings) && ('load' in userSettings)) {
            console.log('just one of properties "url" or "load" must be specified');
            return;
        }


        // default settings
        var defaults = {

            // variables
            minQueryLength: 2,
            maxItemsToDisplay: 20,

            // events and callbacks
            load: function(response) {},
            onLoad: function(response) {},
            onLoadFail: function(xhr) {},
            getOptionsFromResponse: function(response) {},
            onChange: function(value, updateValueForErrorMessage) {}
        };

        // "this" reference
        var plugin = this;

        // add user settings to default settings
        plugin.settings = $.extend({}, defaults, userSettings);


        // elements
        var wnd = $(window);
        var wrapper = $(element);
        var valueField = wrapper.find('input.autocomplete-field_value-field');
        var textField = wrapper.find('input.autocomplete-field_text-field');
        var arrow = null;
        var listBox = null;
        var list = null;


        // variables
        var previousTextValue = '';
        var xhr = null;
        var isLoading = false;
        var isFocused = false;
        var isFirstBlur = null;


        // private methods

        // build value field
        var buildValueField = function() {
            if (valueField.length) {
                return;
            }

            valueField = $('<input/>', {
                type: 'hidden',
                class: 'autocomplete-field_value-field'
            }).appendTo(wrapper);
        };

        // build arrow
        var buildArrow = function() {
            var textFieldWrapper = wrapper.find('div.autocomplete-field_text-field-wrapper');
            arrow = $('<div/>', {
                class: 'autocomplete-field_text-field-arrow'
            }).appendTo(textFieldWrapper);

            arrow.on('click', function() {
                textField.focus();
                return false;
            });
        };

        // build list box
        var buildListBox = function() {
            var listBoxDiv = $('<div/>', {
                class: 'autocomplete-field_list-box'
            });

            var ul = $('<ul/>', {
                class: 'autocomplete-field_list'
            }).appendTo(listBoxDiv);

            listBoxDiv.appendTo(wrapper);
            list = ul;
            listBox = listBoxDiv;
        };

        // add option
        var addOption = function(value, text, addToTop) {
            var option = $('<li/>', {
                class: 'autocomplete-field_list-item',
                html: text
            });
            option.data('value', value);
            //option.hover(
            //    function() {
            //        markAllOptionsAsUnhovered();
            //        $(this).addClass('hovered');
            //    },
            //    function() {
            //        $(this).removeClass('hovered');
            //    }
            //);
            option.on('mousemove', function() {
                markAllOptionsAsUnhovered();
                $(this).addClass('hovered');
            });

            if (addToTop) {
                option.prependTo(list);
            } else {
                option.appendTo(list);
            }
        };

        // get options
        var getOptions = function() {
            return list.find('li');
        };

        // build all options
        var buildAllOptions = function(options) {
            removeAllOptions();
            var optionsLen = (options.length < plugin.settings.maxItemsToDisplay) ? options.length : plugin.settings.maxItemsToDisplay;
            for (var i = 0, len = optionsLen; i < len; i++) {
                var option = options[i];
                addOption(option.value, option.text);
            }
        };

        // set value from option
        var setValueFromOption = function(option) {
            if (!option.length) {
                return;
            }


            markAllOptionsAsUnselected();
            markOptionAsSelected(option);
            setValueFieldValue(option.data('value'));
            setTextFieldValue(option.html().replace('&amp;', '&'));
        };

        // remove all options
        var removeAllOptions = function() {
            getOptions().remove();
        };

        // get hovered option
        var getHoveredOption = function() {
            return list.find('li.hovered');
        };

        // mark all options as unselected
        var markAllOptionsAsUnselected = function() {
            var options = getOptions();
            options.each(function() {
                $(this).removeClass('selected');
            });
        };

        // mark option as selected
        var markOptionAsSelected = function(option) {
            option.addClass('selected');
        };

        // mark all options as unhovered
        var markAllOptionsAsUnhovered = function() {
            var options = getOptions();
            options.each(function() {
                $(this).removeClass('hovered');
            });
        };

        // mark prev option as hovered
        var markPrevOptionAsHovered = function() {
            var options = getOptions();
            if (!options.length) {
                return;
            }

            var currentHoveredOption = getHoveredOption();

            // if there is no hovered option set last option as hovered
            if (!currentHoveredOption.length) {
                options.last().addClass('hovered');
                return;
            }

            // get prev hovered option
            options.each(function(index) {
                var option = $(this);
                if (option.hasClass('hovered')) {
                    var prevOptionIndex = (index === 0) ? (options.length - 1) : (index - 1);
                    var prevOption = $(options.get(prevOptionIndex));
                    currentHoveredOption.removeClass('hovered');
                    prevOption.addClass('hovered');
                    return false;
                }
            });
        };

        // mark next option as hovered
        var markNextOptionAsHovered = function() {
            var options = getOptions();
            if (!options.length) {
                return;
            }

            var currentHoveredOption = getHoveredOption();

            // if there is no hovered option set first option as hovered
            if (!currentHoveredOption.length) {
                options.first().addClass('hovered');
                return;
            }

            // get prev hovered option
            options.each(function(index) {
                var option = $(this);
                if (option.hasClass('hovered')) {
                    var nextOptionIndex = (index === (options.length - 1)) ? 0 : (index + 1);
                    var nextOption = $(options.get(nextOptionIndex));
                    currentHoveredOption.removeClass('hovered');
                    nextOption.addClass('hovered');
                    return false;
                }
            });
        };

        // show list
        var showList = function() {
            var options = getOptions();
            if (!options.length) {
                return;
            }

            // get height
            listBox.addClass('invisible');
            var listBoxHeight = listBox.outerHeight();

            // get max height
            listBox.addClass('max-height');
            var listBoxMaxHeight = parseInt(listBox.css('max-height'), 10);

            // add max-height class
            if (listBoxHeight >= listBoxMaxHeight) {
                listBox.addClass('max-height');
            } else {
                listBox.removeClass('max-height');
            }

            // show list box
            listBox.removeClass('invisible');
            wrapper.addClass('opened');
        };

        // hide list
        var hideList = function() {
            wrapper.removeClass('opened');
        };

        // on focus
        var onFocus = function() {
            isFocused = true;
            wrapper.addClass('focused');
            if (getTextFieldValue().length > plugin.settings.minQueryLength)
                showList();
            else
                hideList();
        };

        // on blur
        var onBlur = function() {
            setFirstBlurFlagToTrue();
            isFocused = false;
            wrapper.removeClass('focused');
        };

        // set first blur flag to true
        var setFirstBlurFlagToTrue = function() {
            if (isFirstBlur === null && getTextFieldValue() != '') {
                // console.log('isFirstBlur = true');
                isFirstBlur = true;
            }
        };

        // set text_field value
        var setTextFieldValue = function(value) {             
            textField.val(value);            
            previousTextValue = value;
            
        };

        // set value_field value
        var setValueFieldValue = function(value) {
            if (isFirstBlur === true) {
                // console.log('first update');
                isFirstBlur = false;
                plugin.settings.onChange(value, true);
                return;
            }

            // console.log('compare values: "' + getValueFieldValue() + '", "' + value + '"');
            if (getValueFieldValue() === value) {
                return;
            }

            valueField.val(value);
            plugin.settings.onChange(value, false);
        };

        // get text_field value
        var getTextFieldValue = function() {
            return textField.val();
        };

        // get value_field value
        var getValueFieldValue = function() {
            return valueField.val();
        };

        // set values on blur
        var setValuesOnBlur = function() {
            var textFieldValue = getTextFieldValue();
            var valueFieldValue = getValueFieldValue();
            var textFieldValueLowerCase = textFieldValue.toLowerCase();

            // data is not loading
            // if (!isLoading) {

                // // field is empty
                // if (!textFieldValue) {
                //     setValueFieldValue('');

                // // text is entered, but value is not choosen
                // } else if (textFieldValue && !valueFieldValue) {
                //     var options = getOptions();
                //     if (options.length === 1) {
                //         setValueFromOption(options.first());
                //     } else {
                //         setValueFieldValue('');
                //     }
                // }

                // field is empty
                if (!textFieldValue) {
                    setValueFieldValue('');

                // text is entered, but value is not choosen
                // } else if (textFieldValue && !valueFieldValue) {

                // text is entered, value is entered or value is empty
                } else {

                    // check entered text in options
                    var foundInOptions = false;
                    var options = getOptions();
                    options.each(function() {
                        var option = $(this);
                        // console.log('compare options', textFieldValueLowerCase, option.html().toLowerCase());

                        // text found in options
                        if (textFieldValueLowerCase === option.text().toLowerCase() || textFieldValueLowerCase === option.html().toLowerCase()) {
                            foundInOptions = true;
                            setValueFromOption(option);
                            return false;
                        }
                    });

                    // text not found in options
                    if (!foundInOptions) {
                        // console.log('not in options');
                        // setValueFieldValue(textFieldValue);
                        // if (options.length) {
                        //     setValueFieldValue('');
                        // } else {
                        //     setValueFieldValue(textFieldValue);
                        // }
                        setValueFieldValue('');
                    }
                }
            // }
        };

        // handle ajax response
        var handleAjaxResponse = function(response) {
            plugin.settings.onLoad(response);
            var options = plugin.settings.getOptionsFromResponse(response);
            if (options.length) {
                buildAllOptions(options);
                if (isFocused) {
                    showList();
                } else {
                    setValuesOnBlur();
                }
                buildArrow();
            } else {
                removeAllOptions();
                hideList();
                wrapper.removeClass('autocomplete-field_text-field-arrow');
            }

            isLoading = false;
            wrapper.removeClass('loading');
        };

        // delay
        var delay = function(callback, ms) {
            var timer = 0;
            return function() {
                var context = this;
                var args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function() {
                    callback.apply(context, args);
                }, ms || 0);
            };
        };

        // load data
        var loadData = function() {
            var textValue = getTextFieldValue();
            if (textValue.trim().length > plugin.settings.minQueryLength) {
                isLoading = true;
                wrapper.addClass('loading');

                // get data by url
                if ('url' in plugin.settings) {
                    if (xhr !== null) {
                        xhr.abort();
                    }

                    // send ajax query
                    xhr = $.ajax({
                        method: 'POST',
                        url: plugin.settings.url,
                        dataType: 'json',
                        data: {
                            query: textValue
                        }
                    })
                        .done(function (response) {
                            // console.log('success');
                            handleAjaxResponse(response);
                        })
                        .fail(function () {
                            // console.log('ajax fail');
                            // console.log(xhr);
                            isLoading = false;
                            wrapper.removeClass('loading');
                            plugin.settings.onLoadFail(xhr);
                        })
                        .always(function () {
                            // console.log('complete');
                        }
                        );

                    // get data by function
                } else {
                    plugin.settings.load(textValue, function (response) {
                        // console.log('load callback');
                        handleAjaxResponse(response);
                    });
                }
            }
            else {
                hideList();
            }
        };


        // public methods
        plugin.addOption = addOption;
        // plugin.removeAllOptions = removeAllOptions;


        // plugin init
        plugin.init = function() {

            // build html-code
            // console.log(plugin.settings);
            buildValueField();
            buildListBox();


            // events

            // text field: on focus
            textField.on('focus', function() {
                onFocus();
            });

            // text field: on blur
            textField.on('blur', function() {
                onBlur();
            });

            // text field: on keydown
            textField.on('keydown', function(e) {
                // console.log('keydown: usual');
                if (e.which === 9) {
                    hideList();
                    setFirstBlurFlagToTrue();
                    setValuesOnBlur();
                }
            });

            // text field: on keyup
            textField.on('keyup', function(e) {
                // console.log('keyup: usual');
                if (e.which === 38) {
                    showList();
                    markPrevOptionAsHovered();
                }
                if (e.which === 40) {
                    showList();
                    markNextOptionAsHovered();
                }
                if (e.which === 13) {
                    setValueFromOption(getHoveredOption());
                    hideList();
                }
            });

            // text field: on keyup with delay
            textField.on('keyup', delay(function(e) {
                // console.log('keyup: delay');
                if (this.value === previousTextValue) {
                    return;
                }

                previousTextValue = this.value;
                setValueFieldValue('');
                loadData();
            }, 500));

            // window: on click
            wnd.on('click', function(e) {
                var clickedElem = $(e.target);

                // text field click
                if (clickedElem.hasClass('autocomplete-field_text-field')) {
                    // console.log('field click');
                    // do nothing

                // option click
                } else if (clickedElem.hasClass('autocomplete-field_list-item') && clickedElem.closest('.autocomplete-field').attr('data-bind') === wrapper.closest('.autocomplete-field').attr('data-bind')) {
                    // console.log('option click');
                    setValueFromOption(clickedElem);
                    hideList();

                // list click (but not option)
                } else if (clickedElem.hasClass('autocomplete-field_list') || 
                    clickedElem.hasClass('autocomplete-field_list-box')) {
                    // console.log('list click');
                    // do nothing

                // outside click (blur)
                } else {
                    // console.log('outside click');
                    hideList();
                    setValuesOnBlur();
                }
            });

        };

        // start plugin init
        plugin.init();

    };


    // add plugin to jquery
    $.fn.autocompleteField = function(userSettings) {

        // dom-elements cycle
        return this.each(function() {
            if (undefined == $(this).data('autocompleteField')) {
                var plugin = new $.autocompleteField(this, userSettings);
                $(this).data('autocompleteField', plugin);
            }
        });
    };

})(jQuery);
