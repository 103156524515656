(function ($, ko) {
    
    let urlComponentLoader = {
        loadTemplate: function(name, templateConfig, callback) {
            if (templateConfig.url) {
                // Uses jQuery's ajax facility to load the markup from a file
                let version = $.cdh.version;
                let fullUrl = '/components/' + templateConfig.url + '?v=' + version;
                $.get(fullUrl, function(markupString) {
                    // We need an array of DOM nodes, not a string.
                    // We can use the default loader to convert to the
                    // required format.
                    ko.components.defaultLoader.loadTemplate(name, markupString, callback);
                });
            } else {
                // Unrecognized config format. Let another loader handle it.
                callback(null);
            }
        },
        loadViewModel: function(name, viewModelConfig, callback) {
            if (viewModelConfig && typeof viewModelConfig.createViewModel === 'function') {
                let currentCreateViewModel = viewModelConfig.createViewModel;
                viewModelConfig.createViewModel = function (params, componentInfo) {
                    let widgetInfo = {
                        updateHeight: updateContainerHeight(componentInfo.element),
                        remove: function () {
                            $.cdh.helper.removeWidget($(componentInfo.element));
                        }
                    };
                    return currentCreateViewModel(params, componentInfo, widgetInfo);
                };
                ko.components.defaultLoader.loadViewModel(name, viewModelConfig, callback);
            } else {
                callback(null);
            }
        }
    };

    ko.components.loaders.unshift(urlComponentLoader);
    
}(jQuery, ko));