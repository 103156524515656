import 'script-loader!knockout/build/output/knockout-latest'
import 'script-loader!../Scripts/script-loader/knockout.validation.min'
import 'script-loader!../Scripts/script-loader/knockout-postbox.min'
import 'script-loader!../Scripts/script-loader/moment'

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../Scripts/ko/', true, /\.js$/));

import 'pickadate/lib/picker'
import 'pickadate/lib/picker.date'


let context = null;

if (typeof window !== 'undefined') {
    context = window;
} else if (typeof global !== 'undefined') {
    context = global;
}

if (context) {
    context.ko = ko;
}

